import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { useUpdate, useListController, useGetOne, CRUD_GET_ONE, useVersion } from 'react-admin';
import { get } from 'lodash';
import ListView from './ListView';

const usePagingStoreController = (listProps) => {
  const version = useVersion();

  const { data, loaded } = useGetOne('rowsPerPageSettings', listProps.resource, {
    version,
    action: CRUD_GET_ONE,
  });

  const [update] = useUpdate('rowsPerPageSettings', listProps.resource);

  return {
    update,
    data,
    loaded,
  };
};

const ListViewWrapped = ({ perPage, update, ...props }) => {
  const controllerProps = useListController({ ...props, perPage });

  const setPerPage = (value) => {
    update({
      payload: {
        data: {
          perPage: value,
        },
      },
    });

    controllerProps.setPerPage(value);
  };

  const customOnToggleItem = (id, record) => {
    if (props.onToggleItem) {
      props.onToggleItem?.(id, record);
    }

    controllerProps.onToggleItem(id);
  };

  const customOnSelect = (newIds, data) => {
    if (props.onSelect) {
      props.onSelect(newIds, data);
    }

    controllerProps.onSelect(newIds);
  };

  return (
    <ListView
      {...props}
      {...controllerProps}
      setPerPage={setPerPage}
      onToggleItem={customOnToggleItem}
      onSelect={customOnSelect}
    />
  );
};

const List = (props) => {
  const { data: perPageStore, update, loaded } = usePagingStoreController(props);
  const perPage = get(perPageStore, 'perPage', 10);

  if (!loaded) {
    return <CircularProgress />;
  }

  return <ListViewWrapped perPage={perPage} update={update} {...props} />;
};

export default List;
