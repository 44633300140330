import React from 'react';
import { DatagridBody as RADatagridBody } from 'react-admin';
import DatagridRow from './DatagridRow';

const DatagridBody = (props) => {
  const { showExpandButton, ...rest } = props;

  return <RADatagridBody {...rest} row={<DatagridRow {...props} />} />;
};

export default DatagridBody;
