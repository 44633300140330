import React, {
  cloneElement,
  createElement,
  isValidElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Checkbox, Fade, TableCell, TableRow } from '@material-ui/core';
import { linkToRecord } from 'react-admin';
import { useHistory } from 'react-router';
import ExpandRowButton from 'ra-ui-materialui/lib/list/ExpandRowButton';

const computeNbColumns = (expand, children, hasBulkActions, showExpandButton) =>
  expand
    ? (showExpandButton ? 1 : 0) +
      (hasBulkActions ? 1 : 0) +
      React.Children.toArray(children).filter((child) => !!child).length
    : 0;

const DatagridRow = (props) => {
  const {
    record,
    resource,
    id,
    onToggleItem,
    children,
    selected,
    basePath,
    hasBulkActions,
    className,
    hover = true,
    rowClick,
    style,
    classes,
    expand,
    showExpandButton = true,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const history = useHistory();

  const [nbColumns, setNbColumns] = useState(
    computeNbColumns(expand, children, hasBulkActions, showExpandButton)
  );

  useEffect(() => {
    const newNbColumns = computeNbColumns(expand, children, hasBulkActions, showExpandButton);
    if (newNbColumns !== nbColumns) {
      setNbColumns(newNbColumns);
    }
  }, [expand, nbColumns, children, hasBulkActions, showExpandButton]);

  const handleToggleExpand = useCallback(
    (event) => {
      setExpanded(!expanded);
      event.stopPropagation();
    },
    [expanded]
  );
  const handleToggleSelection = useCallback(
    (event) => {
      onToggleItem(id, record);
      event.stopPropagation();
    },
    [id, onToggleItem, record]
  );

  const handleClick = useCallback(
    async (event) => {
      if (!rowClick) return;
      const effect =
        typeof rowClick === 'function' ? await rowClick(id, basePath, record) : rowClick;
      switch (effect) {
        case 'edit':
          history.push(linkToRecord(basePath, id));
          return;
        case 'show':
          history.push(linkToRecord(basePath, id, 'show'));
          return;
        case 'expand':
          handleToggleExpand(event);
          return;
        case 'toggleSelection':
          handleToggleSelection(event);
          return;
        default:
          if (effect) history.push(effect);
          return;
      }
    },
    [basePath, history, handleToggleExpand, handleToggleSelection, id, record, rowClick]
  );

  return (
    <Fade in>
      <>
        <TableRow key={id} onClick={handleClick} hover={hover} style={style} className={className}>
          {!!expand && showExpandButton && (
            <TableCell padding="none" className={classes.expandIconCell}>
              <ExpandRowButton
                classes={classes}
                expanded={expanded}
                onClick={handleToggleExpand}
                expandContentId={`${id}-expand`}
              />
            </TableCell>
          )}
          {hasBulkActions && (
            <TableCell>
              <Checkbox checked={selected} onClick={handleToggleSelection} color="primary" />
            </TableCell>
          )}
          {React.Children.map(children, (field) => (
            <TableCell key={`${id}-${field.props.source}`} align={field.props.textAlign}>
              {React.cloneElement(field, {
                record,
                basePath,
                resource,
              })}
            </TableCell>
          ))}
        </TableRow>
        {expand && expanded && (
          <TableRow
            className={classes.expandRoot}
            key={`${id}-expand`}
            id={`${id}-expand`}
            data-test="expand-root">
            <TableCell className={classes.expandCell} colSpan={nbColumns}>
              {isValidElement(expand)
                ? cloneElement(expand, {
                    // @ts-ignore
                    record,
                    basePath,
                    resource,
                    id: String(id),
                  })
                : createElement(expand, {
                    record,
                    basePath,
                    resource,
                    id: String(id),
                  })}
            </TableCell>
          </TableRow>
        )}
      </>
    </Fade>
  );
};

export default DatagridRow;
