export interface UserDetails {
  billingUserId: number;
  blockedAt: string;
  blockReason: string;
  cognitoEnabled: boolean;
  cognitoStatus: string;
  cognitoUserId: string;
  createdAt: string;
  deletedAt: string;
  email: string;
  firstName: string;
  fraudAllowedAt: string;
  fraudBlockedAt: string;
  fraudDescription: Record<string, any>;
  fraudDetectedAt: string;
  fraudReason: string;
  id: string;
  lastName: string;
  note: string;
  phone: string;
  singlePayerAccountNumber: string;
  trackingNumberSource: string;
  trackingNumberUserAccount: string;
  shippingBlocked: boolean;
}

export enum TransactionType {
  Purchase = 'PURCHASE',
  Deposit = 'DEPOSIT',
  AdminPurchase = 'ADMIN_PURCHASE',
  AdminDeposit = 'ADMIN_DEPOSIT',
  PurchaseAdjustment = 'PURCHASE_ADJUSTMENT',
  DepositAdjustment = 'DEPOSIT_ADJUSTMENT',
  Refund = 'REFUND',
  CreditCodes = 'CREDIT_CODE',
}

export interface RefundTransaction {
  amount: number;
  id: string;
  recordId: string;
}
