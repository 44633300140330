import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import ActionButton from 'components/buttons/ActionButton';
import { UserDetails } from '../types';

export interface Props {
  user: UserDetails;
}

const BalanceRefundButton = ({ user }: Props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleRefund = async () => {
    try {
      await dataProvider.post('refundAllBalance', { userId: user.id });
    } catch (error: any) {
      notify(error?.message ?? error);
    }
  };

  return <ActionButton onClick={handleRefund}>Refund balance</ActionButton>;
};

export default BalanceRefundButton;
