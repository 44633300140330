import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useDataProvider } from 'react-admin';
import { Dialog, Divider } from 'components/common';
import { Group, GroupContent, GroupItem } from 'components/common/Group';
import { LabeledValueField } from 'components/fields';
import { Input, Select } from 'components/form';
import { required } from 'components/form/validationRules';
import { getAmountObject, getCommand, getCurrency } from 'pages/users/helpers/balance';
import UserInfo from './UserInfo';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  calcBlock: {},

  calcRow: {
    paddingTop: theme.spacing(2),
  },

  calcTotal: {
    fontWeight: 'bold',
    fontSize: 20,
  },
}));

const comments = ['Courtesy Credit', 'Adjustment from Rollo Team', 'Refund', 'Other'];

const ChangeBalanceDialog = ({ user, action, balance, onComplete, onCancel }) => {
  const classes = useStyles();
  const [confirmation, setConfirmation] = useState(false);
  const dataProvider = useDataProvider();

  const handleSubmitForm = (values) =>
    dataProvider
      .post('balanceChange', {
        id: user.id,
        command: action.commandCode,
        data: {
          amounts: {
            amount: Number(values.amount),
            currency: action.currencyCode,
          },
          comments: values.comment === 'Other' ? values.note : values.comment,
        },
      })
      .then(() => {
        setConfirmation(false);
        onComplete();
      })
      .catch((err) => ({ [FORM_ERROR]: err.message }));

  if (!action) {
    return null;
  }

  const currency = getCurrency(action.currencyCode);
  const command = getCommand(action.commandCode);
  const amount = getAmountObject(balance, action.currencyCode);

  const initialValues = {
    amount: 0,
    comment: 'Adjustment from Rollo Team',
    note: '',
  };

  if (!command) {
    return null;
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      render={({ handleSubmit, submitting, submitError, values }) => {
        const calc = {
          current: amount.balance,
          result: command.calc(amount.balance, Number(values.amount)),
        };

        if (confirmation) {
          return (
            <form onSubmit={handleSubmit}>
              <Dialog
                open={true}
                title={`${command.caption} ${currency.caption}`}
                submitting={submitting}
                error={submitError}
                confirmText={command.caption}
                cancelText="Back"
                onConfirm={handleSubmit}
                onCancel={() => setConfirmation(false)}>
                <Typography variant="body1">
                  You are about to{' '}
                  <b>
                    {command.caption} {currency.format(values.amount)}
                  </b>{' '}
                  for
                </Typography>
                <UserInfo user={user} />
                <Typography variant="body1">
                  With comment <b>{values.comment === 'Other' ? values.note : values.comment}</b>
                </Typography>
              </Dialog>
            </form>
          );
        }

        return (
          <Dialog
            open={true}
            title={`${command.caption} ${currency.caption}`}
            confirmText={command.caption}
            onConfirm={() => setConfirmation(true)}
            onCancel={onCancel}>
            <UserInfo user={user} />
            <Divider />
            <Group>
              <GroupContent className={classes.calcBlock}>
                <GroupItem wide>
                  <LabeledValueField
                    classes={{ root: classes.calcRow }}
                    label="Current Amount"
                    record={calc}
                    source="current"
                    formatter={currency.format}
                  />
                </GroupItem>
                <GroupItem wide>
                  <Input
                    name="amount"
                    label={`Amount to ${command.caption}`}
                    inputProps={{ type: 'number', inputProps: { min: 0 } }}
                    validate={required}
                  />
                </GroupItem>
                <GroupItem wide>
                  <LabeledValueField
                    classes={{ root: classes.calcRow, value: classes.calcTotal }}
                    label="Result Amount"
                    record={calc}
                    source="result"
                    formatter={currency.format}
                  />
                </GroupItem>
                <GroupItem wide>
                  <Select
                    name="comment"
                    label="Comment"
                    options={comments.map((c) => ({ value: c, label: c }))}
                    validate={required}
                  />
                </GroupItem>
                {values.comment === 'Other' && (
                  <GroupItem wide>
                    <Input name="note" label="Note" />
                  </GroupItem>
                )}
              </GroupContent>
            </Group>
          </Dialog>
        );
      }}
    />
  );
};

export default ChangeBalanceDialog;
