import React, { useState } from 'react';
import { useDataProvider, useRefresh } from 'react-admin';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { Dialog } from 'components/common';
import UserInfo from './UserInfo';
import { Input } from 'components/form';
import { required } from 'components/form/validationRules';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.secondary.contrastText,
  },
}));

const BulkRefundsButton = (props) => {
  const { user, selectedTransactions, onUnselectItems, setSelectedTransactions } = props;

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmitForm = async ({ description }) => {
    try {
      const transactions: Record<string, number> = {};

      selectedTransactions.forEach((item) => (transactions[item.id] = item.amount));

      const response = await dataProvider.post('bulkRefund', {
        userId: user.id,
        data: {
          description,
          transactions,
        },
      });

      onUnselectItems();
      setSelectedTransactions([]);

      refresh();

      return response;
    } catch (error: any) {
      return { [FORM_ERROR]: error?.message };
    }
  };

  return (
    <>
      <Button className={classes.button} size="small" variant="outlined" onClick={handleOpenDialog}>
        Refund transactions
      </Button>

      {openDialog && (
        <Form
          onSubmit={handleSubmitForm}
          initialValues={{
            description: 'Refund from Rollo Team',
          }}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Dialog
                  open={openDialog}
                  title="Refund transactions"
                  onCancel={handleCloseDialog}
                  confirmText="Refund"
                  onConfirm={handleSubmit}
                  submitting={submitting}>
                  <UserInfo user={user} />

                  <Input
                    name="description"
                    label="Description"
                    inputProps={{ type: 'text' }}
                    validate={required}
                  />
                </Dialog>
              </form>
            );
          }}
        />
      )}
    </>
  );
};

export default BulkRefundsButton;
