import { useColumnConfig } from 'components/layout/ColumnsConfigButton';
import DatagridHeaderCell from 'components/list/DatagridHeaderCell';
import React, { Children, cloneElement, createElement, isValidElement, useCallback } from 'react';
import { makeStyles, Table, TableHead, TableRow, TableCell, Checkbox } from '@material-ui/core';
import { DatagridLoading } from 'react-admin';
import classnames from 'classnames';
import DatagridBody from './DatagridBody';
import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: 'auto',
  },
  thead: {},
  tbody: {
    height: 'inherit',
  },
  headerRow: {
    textAlign: 'left',
  },
  headerCell: {},
  checkbox: {},
  row: {},
  clickableRow: {
    cursor: 'pointer',
  },
  rowEven: {},
  rowOdd: {},
  rowCell: {},
  expandHeader: {
    padding: 0,
    width: theme.spacing(6),
  },
  expandIconCell: {
    width: theme.spacing(6),
  },
  expandIcon: {
    padding: theme.spacing(1),
    transform: 'rotate(-90deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expanded: {
    transform: 'rotate(0deg)',
  },
  expandCell: {},
  expandRoot: {},
}));

const Datagrid = ({
  classes: classesOverride,
  ...props
}: {
  classes?: any;
  [key: string]: any;
}) => {
  const classes = useStyles({ classes: classesOverride });

  const {
    basePath,
    body = <DatagridBody />,
    children,
    className,
    currentSort,
    data = {},
    expand,
    hasBulkActions = false,
    hover,
    ids = [],
    loaded,
    onSelect,
    onToggleItem,
    resource,
    rowClick,
    rowStyle,
    showHeader = true,
    selectedIds = [],
    setSort,
    total,
    version,
    widths,
    showExpandButton = true,
  } = props;

  const updateSort = useCallback(
    (event) => {
      event.stopPropagation();
      setSort(event.currentTarget.dataset.sort);
    },
    [setSort]
  );

  const handleSelectAll = useCallback(
    (event) => {
      if (event.target.checked) {
        onSelect(ids.concat(selectedIds.filter((id) => !ids.includes(id))), data);
      } else {
        onSelect([], data);
      }
    },
    [ids, onSelect, selectedIds, data]
  );

  const { data: colConfig, loading: colConfLoading } = useColumnConfig(resource);

  if (loaded === false || colConfLoading) {
    return (
      <DatagridLoading
        classes={classes}
        className={className}
        expand={expand}
        hasBulkActions={hasBulkActions}
        nbChildren={React.Children.count(children)}
      />
    );
  }

  if (loaded && (ids.length === 0 || total === 0)) {
    return null;
  }

  return (
    <Table className={classnames(classes.table, className)}>
      {widths && (
        <colgroup>
          {widths.map((width, index) => (
            <col key={index} width={width} />
          ))}
        </colgroup>
      )}
      {showHeader && (
        <TableHead className={classes.thead}>
          <TableRow className={classnames(classes.row, classes.headerRow)}>
            {expand && showExpandButton && (
              <TableCell padding="none" className={classes.expandHeader} />
            )}
            {hasBulkActions && (
              <TableCell padding="none">
                <Checkbox
                  className="select-all"
                  color="primary"
                  checked={
                    selectedIds.length > 0 &&
                    ids.length > 0 &&
                    ids.every((id) => selectedIds.includes(id))
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
            )}
            {Children.map(children, (field, index) => {
              const { props } = field;
              const showColumn = get(colConfig, `fields.${props.source}`, true);

              if (props.header) {
                return (
                  <TableCell
                    key={props.source || index}
                    className={classes.headerCell}
                    align={props.textAlign}
                    variant="head">
                    {createElement(props.header, {
                      fields: children,
                      basePath,
                      resource,
                    })}
                  </TableCell>
                );
              }

              return isValidElement(field) && showColumn ? (
                <DatagridHeaderCell
                  className={classes.headerCell}
                  currentSort={currentSort}
                  field={field}
                  isSorting={currentSort.field === (props.sortBy || props.source)}
                  key={props.source || index}
                  resource={resource}
                  updateSort={updateSort}
                  padding="none"
                />
              ) : null;
            })}
          </TableRow>
        </TableHead>
      )}
      {cloneElement(
        body,
        {
          basePath,
          className: classes.tbody,
          classes,
          expand,
          rowClick,
          data,
          hasBulkActions,
          hover,
          ids,
          onToggleItem,
          resource,
          rowStyle,
          selectedIds,
          version,
          showExpandButton,
        },
        Children.map(children, (field) =>
          get(colConfig, `fields.${field.props.source}`, true) ? field : null
        )
      )}
    </Table>
  );
};

export default Datagrid;
